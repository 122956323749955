<script lang="ts">
  import { Input, Label } from 'flowbite-svelte';
  import { createEventDispatcher } from 'svelte';
  import { MagnifyingGlassSolid } from 'svelte-awesome-icons';

  export let searchPlaceholder: string;
  export let value: string = '';

  let isDarkTheme = document.documentElement.classList.contains('dark');

  const dispatch = createEventDispatcher();

  const handleSearch = (event: Event) => {
    const target = event.target as HTMLInputElement;
    dispatch('search', target.value);
  };
</script>

<Label class="relative">
  <MagnifyingGlassSolid
    color={isDarkTheme ? 'white' : 'black'}
    size="16"
    class="absolute left-3 top-1/2 transform -translate-y-1/2"
  />
  <Input
    id="search"
    defaultClass="mr-4"
    class=" pl-10 w-96 !text-sm text-gray-900 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-900 dark:placeholder-gray-300 dark:text-white"
    placeholder={searchPlaceholder}
    size="md"
    {value}
    on:input={handleSearch}
  />
</Label>
