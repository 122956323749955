import * as projectApi from '$lib/api/project';
import type { AllProjectPageData } from '$lib/types/page';
import type { ProjectDetail, ProjectMilestone, ProjectSummary } from '$lib/types/project';
import type { CreateStoryRequest, GenerateStoriesRequest } from '$lib/types/request';
import type {
  ProjectInvitationResponse,
  ProjectMilestoneResponse,
  ProjectSummaryListResponse,
  StoryDetailResponse,
  WorkflowResponse,
  WorkspaceMembershipResponse,
  WorkspacePermissionsResponse,
  WorkspaceResponse,
} from '$lib/types/response';
import type { MultiSelectValue } from '$lib/types/select';
import { calculateWeeksBetweenDates } from '$lib/utils/date';
import { getProjectStatus } from '$lib/utils/status';

export const getWorkspace = async (): Promise<AllProjectPageData> => {
  const data = (await projectApi.getWorkspace()).data;
  const uclWorkspace = data.find((d: WorkspaceResponse) => d.slug === 'ucl');
  if (!uclWorkspace) {
    throw new Error('No workspace found');
  }

  return {
    workspaceId: uclWorkspace.id,
    invitedProjects: uclWorkspace.invitedProjects,
  };
};

export const getWorkspacePermissions = async (workspaceId: string): Promise<WorkspacePermissionsResponse> => {
  return (await projectApi.getWorkspacePermissions(workspaceId)).data;
};

export const getWorkspaceMembership = async (workspaceId: string): Promise<WorkspaceMembershipResponse> => {
  return (await projectApi.getWorkspaceMembership(workspaceId)).data;
};

export const getProjects = async (workSpaceId: string, params: URLSearchParams): Promise<ProjectSummary[]> => {
  const data = (await projectApi.getProjects(workSpaceId, params)).data;
  return data.map((p: ProjectSummaryListResponse) => ({
    ...p,
    status: getProjectStatus(p.statusScore),
    notes: p.notes ?? '',
    companyName: p.companyName ?? '',
    studentName: p.studentName ?? '',
  }));
};

export const getProject = async (id: string): Promise<ProjectDetail> => {
  const data = (await projectApi.getProject(id)).data;
  return {
    id: data.id,
    name: data.name,
    createdAt: new Date(data.createdAt),
    slug: data.slug,
    startsAt: data.startsAt ? new Date(data.startsAt) : null,
    report: data.report ?? '',
    notes: data.notes ?? '',
    progress: data.progress,
    pmPerms: data.pmPerms,
  };
};

export const acceptProjectInvitation = async (id: string): Promise<ProjectInvitationResponse> => {
  return (await projectApi.acceptProjectInvitation(id)).data;
};

export const getProjectMilestones = async (project: ProjectDetail): Promise<ProjectMilestone[]> => {
  const data = (await projectApi.getProjectMilestones(project.id)).data;
  return formatMilestones(data, project);
};

export const getWorkflow = async (id: string, slug: string): Promise<WorkflowResponse> => {
  return (await projectApi.getWorkflow(id, slug)).data;
};

export async function createStories(
  id: string,
  slug: string,
  stories: CreateStoryRequest[]
): Promise<StoryDetailResponse> {
  return (await projectApi.createStories(id, slug, stories)).data;
}

export async function generateStories(projectId: string, userId: string, data: GenerateStoriesRequest): Promise<void> {
  await projectApi.generateStories(projectId, userId, data);
}

export const getProjectTagsAsSelectValues = async (id: string): Promise<MultiSelectValue[]> => {
  return (await projectApi.getProjectTags(id)).data
    .filter((t) => t.name)
    .map((t) => {
      return {
        value: t.id,
        name: t.name,
        color: t.color,
        textColor: t.textColor,
        isSelected: false,
      };
    });
};

export const formatMilestones = (data: ProjectMilestoneResponse[], project: ProjectDetail): ProjectMilestone[] => {
  return data.map((milestone: ProjectMilestoneResponse) => {
    const completionDueAt = new Date(milestone.completionDueAt);
    const startsAt = project.startsAt ? new Date(project.startsAt) : null;
    return {
      ...milestone,
      completionDueAt: completionDueAt,
      achievedAt: milestone.achievedAt ? new Date(milestone.achievedAt) : null,
      weeks: startsAt ? calculateWeeksBetweenDates(startsAt, completionDueAt) : 0,
      isAchieved: milestone.achievedAt !== null,
    };
  });
};
