import type { CreateStoryRequest, GenerateStoriesRequest } from '$lib/types/request';
import type {
  AxiosPromise,
  ProjectDetailResponse,
  ProjectInvitationResponse,
  ProjectMilestoneResponse,
  ProjectSummaryListResponse,
  StoryDetailResponse,
  StoryTagResponse,
  WorkflowResponse,
  WorkspaceMembershipResponse,
  WorkspacePermissionsResponse,
  WorkspaceResponse,
} from '$lib/types/response';
import { mlApi, taigaApi } from '$src/axios/api';

const PROJECTS_API_BASE_ROUTE = 'projects';
const MY_PROJECTS_API_BASE_ROUTE = 'my/projects';

export const getWorkspace = (): AxiosPromise<WorkspaceResponse[]> => {
  return taigaApi.get('my/workspaces');
};

export const getWorkspacePermissions = (workspaceId: string): AxiosPromise<WorkspacePermissionsResponse> => {
  return taigaApi.get(`my/workspaces/${workspaceId}/permissions`);
};

export const getWorkspaceMembership = (workspaceId: string): AxiosPromise<WorkspaceMembershipResponse> => {
  return taigaApi.get(`workspaces/${workspaceId}/membership`);
};

export const getProjects = (
  workSpaceId: string,
  params: URLSearchParams
): AxiosPromise<ProjectSummaryListResponse[]> => {
  return taigaApi.get(`pm/workspaces/${workSpaceId}/${PROJECTS_API_BASE_ROUTE}`, { params });
};

export const getProject = (id: string): AxiosPromise<ProjectDetailResponse> => {
  return taigaApi.get(PROJECTS_API_BASE_ROUTE + `/${id}`);
};

export const acceptProjectInvitation = (id: string): AxiosPromise<ProjectInvitationResponse> => {
  return taigaApi.post(PROJECTS_API_BASE_ROUTE + `/${id}/invitations/accept`);
};

export const getProjectMilestones = (id: string): AxiosPromise<ProjectMilestoneResponse[]> => {
  return taigaApi.get(`${MY_PROJECTS_API_BASE_ROUTE}/${id}/milestones`);
};

export const getWorkflow = (id: string, slug: string): AxiosPromise<WorkflowResponse> => {
  return taigaApi.get(`${PROJECTS_API_BASE_ROUTE}/${id}/workflows/${slug}`);
};

export const getProjectTags = (id: string): AxiosPromise<StoryTagResponse[]> => {
  return taigaApi.get(`${PROJECTS_API_BASE_ROUTE}/${id}/story-tags`);
};

export function createStories(
  id: string,
  slug: string,
  stories: CreateStoryRequest[]
): AxiosPromise<StoryDetailResponse> {
  return taigaApi.post(`pm/projects/${id}/workflows/${slug}/stories`, stories);
}

export function generateStories(projectId: string, userId: string, data: GenerateStoriesRequest): AxiosPromise<void> {
  return mlApi.post(`${PROJECTS_API_BASE_ROUTE}/${projectId}/users/${userId}/generate_tasks`, data);
}
