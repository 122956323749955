import { AllProjectColumns, ProjectDetailsTab, ProjectOrderByOption } from '$lib/enums/project';
import type { Column, Tab } from '$lib/types/project';
import type { MultiSelectValue } from '$lib/types/select';
import type { ProjectTableSettings } from '$lib/types/table';
import { TaigaPermissions } from './constants';

export const TabsConfig: Tab[] = [
  { name: ProjectDetailsTab.OVERVIEW, permission: TaigaPermissions.CAN_ACCESS_PROJECT_OVERVIEW },
  { name: ProjectDetailsTab.BOARD, permission: TaigaPermissions.CAN_ACCESS_PROJECT_BOARD },
  { name: ProjectDetailsTab.ACTIONS, permission: TaigaPermissions.CAN_ACCESS_PROJECT_ACTIONS },
  { name: ProjectDetailsTab.REPORTS, permission: TaigaPermissions.CAN_ACCESS_PROJECT_REPORTS },
  { name: ProjectDetailsTab.MEMBERS, permission: TaigaPermissions.CAN_ACCESS_PROJECT_MEMBERS },
  { name: ProjectDetailsTab.REPOSITORY, permission: TaigaPermissions.CAN_ACCESS_PROJECT_FILES },
];

export const AllProjectColumnsConfig: Column[] = [
  {
    name: AllProjectColumns.NAME,
    canHide: false,
    isHidden: false,
    sort: [ProjectOrderByOption.NAME, ProjectOrderByOption.NAME_DESC],
  },
  {
    name: AllProjectColumns.PROGRESS,
    permission: TaigaPermissions.ACCESS_PROJECT_PROGRESS,
    canHide: true,
    isHidden: false,
    sort: [ProjectOrderByOption.PROGRESS, ProjectOrderByOption.PROGRESS_DESC],
  },
  {
    name: AllProjectColumns.COMPANY,
    canHide: true,
    isHidden: false,
    sort: [ProjectOrderByOption.COMPANY_NAME, ProjectOrderByOption.COMPANY_NAME_DESC],
  },
  {
    name: AllProjectColumns.STUDENT,
    permission: TaigaPermissions.ACCESS_PROJECT_STUDENT_NAME,
    canHide: true,
    isHidden: false,
    sort: [ProjectOrderByOption.STUDENT_NAME, ProjectOrderByOption.STUDENT_NAME_DESC],
  },
  {
    name: AllProjectColumns.STATUS,
    permission: TaigaPermissions.ACCESS_PROJECT_STATUS,
    canHide: false,
    isHidden: false,
    sort: [ProjectOrderByOption.STATUS, ProjectOrderByOption.STATUS_DESC],
  },
  { name: AllProjectColumns.NOTES, permission: TaigaPermissions.ACCESS_PROJECT_NOTES, canHide: true, isHidden: false },
];

export const ProjectTableSettingsConfig: ProjectTableSettings = {
  orderBy: ProjectOrderByOption.NAME,
  statusFilter: 'All Projects',
  customFilter: 'No filter',
  searchValue: '',
};

export const PrioritiesConfig: MultiSelectValue[] = [
  { name: 'None', value: 'none', isSelected: false, color: '#E6E7EC' },
  {
    name: 'Low',
    value: 'low',
    isSelected: false,
    color: '#27A687',
  },
  {
    name: 'Medium',
    value: 'medium',
    isSelected: false,
    color: '#FCDE53',
  },
  {
    name: 'High',
    value: 'high',
    isSelected: false,
    color: '#FF6472',
  },
];
