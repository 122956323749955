<script>
  import gfm from '@bytemd/plugin-gfm';
  import highlightSsr from '@bytemd/plugin-highlight-ssr';
  import mathSsr from '@bytemd/plugin-math-ssr';
  import { Viewer } from 'bytemd';
  export let source = '';
  const plugins = [gfm(), mathSsr(), highlightSsr()];
</script>

<div class="text-wrap">
  <Viewer value={source} {plugins} />
</div>
