export enum ProjectDetailsTab {
  OVERVIEW = 'Overview',
  ACTIONS = 'Actions',
  REPORTS = 'Reports',
  MEMBERS = 'Members',
  BOARD = 'Board',
  REPOSITORY = 'Files',
}

export enum ProjectStatus {
  ON_TRACK = 'On track',
  CAUTION = 'Caution',
  BLOCKED = 'Blocked',
}

export enum ProjectOrderByOption {
  NAME = 'name',
  NAME_DESC = '-name',
  STUDENT_NAME = 'student_name',
  STUDENT_NAME_DESC = '-student_name',
  COMPANY_NAME = 'company_name',
  COMPANY_NAME_DESC = '-company_name',
  PROGRESS = 'progress',
  PROGRESS_DESC = '-progress',
  STATUS = 'status_score',
  STATUS_DESC = '-status_score',
}

export enum AllProjectColumns {
  NAME = 'Name',
  PROGRESS = 'Progress',
  COMPANY = 'Company',
  STUDENT = 'Student',
  STATUS = 'Status',
  NOTES = 'State of Play',
}

export enum WorkflowStatusName {
  NEW = 'New',
  READY = 'Ready',
  IN_PROGRESS = 'In progress',
  DONE = 'Done',
}
