<script lang="ts">
  import { Modal } from 'flowbite-svelte';
  import { createEventDispatcher } from 'svelte';
  import Markdown from '../generic/Markdown.svelte';

  export let notes: string = '';
  export let isOpen = false;

  const dispatch = createEventDispatcher();

  $: {
    dispatch('handleShowModal', isOpen);
  }
</script>

<Modal title={'State of Play'} bind:open={isOpen} autoclose outsideclose>
  <div class="break-words max-h-[25vh] h-[25vh] overflow-y-auto text-gray-700 dark:text-gray-200">
    <Markdown source={notes} />
  </div>
</Modal>
