import { ProjectStatus } from '$lib/enums/project';

export function getProjectStatus(statusScore: number): ProjectStatus {
  if (statusScore < 1 / 3) {
    return ProjectStatus.BLOCKED;
  } else if (statusScore < 2 / 3) {
    return ProjectStatus.CAUTION;
  } else {
    return ProjectStatus.ON_TRACK;
  }
}
